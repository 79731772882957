<template>
  <section class="donut-c3">
    <vue-c3 :handler="donutcharthandler"></vue-c3>
  </section>
</template>

<script>
import Vue from "vue";
import VueC3 from "vue-c3";

export default {
  name: "donut-c3",
  components: {
    VueC3,
  },
  props: ["columns"],

  data() {
    return {
      donutcharthandler: new Vue(),
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    init() {
      let options = {
        data: {
          columns: this.columns,
          type: "donut",
          // empty: {
          //   label: {
          //     text: "No Data",
          //   },
          // },
          labels: true,
          // transition: {
          //   duration: 000,
          // },
        },
        color: {
          pattern: [
            "rgba(88,216,163,1)",
            "rgba(4,189,254,0.6)",
            "rgba(237,28,36,0.6)",
            "rgba(188,416,163,1)",
            "rgba(4,89,254,0.6)",
            "rgba(237,128,36,0.6)",
          ],
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        },
        bar: {
          width: {
            ratio: 0.7, // this makes bar width 50% of length between ticks
          },
        },
      };
      this.donutcharthandler.$emit("init", options);
    },
  },
};
</script>